@import './_colors';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

section {
  transition: filter 400ms, -webkit-filter 400ms
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  // top: 0;
  position: relative;
  z-index: 4;
}

main {
  position: relative;
  // z-index: 2;
  height: 100%;
  margin-bottom: 341px;
  background-color: #fbfbf9;
  box-shadow: 3px 2px 2px 0px rgba(51, 51, 51, 0.5);
}

header {
  width: 100vw;
  height: 7em;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transition: height 0.3s;
}

header.smaller {
  height: 5em;
}

.headerHolder {
  transition: height 0.3s;
  height: 90%;
}

.headerOpacity {
  background-color: rgba(212, 237, 244, 0.65) !important;
}

.headerText {

  a,
  a:visited,
  a:active,
  a:hover,
  a:link {
    color: $color-peopleOrange;
    text-decoration: none;
    font-size: 1.5em;
  }
}

.logoHolder {
  width: 100%;

  svg {
    width: 115%;
    height: 100%;
    transition: 0.3s;

    &#boy {
      transform: translate(-15px, -10px);
    }

    &#logo {
      transform: translate(-30px, 1px);
    }

    &#girl {
      transform: translate(-30px, 9px);
    }

    &.smaller {
      width: 100%;
      transition: 0.3s;

      &#boy {
        transform: translate(-20px, -5px);
      }

      &#logo {
        transform: translate(-40px, 2px);
      }

      &#girl {
        transform: translate(-50px, 10px);
      }
    }
  }
}

.sidenav {
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  left: 0;
  background-color: $color-salmon;
  /* Black*/
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 60px;
  /* Place content 60px from the top */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: black;
  display: block;
  transition: 0.3s;
}

.sidenav-open {
  width: 250px;
}

.sidenav .closebtn {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.wordOfTheDayButton,
.heroButton {
  background-color: $color-lightBlue  !important;

  a:visited,
  a:active,
  a:hover,
  a:link {
    color: $color-peopleOrange;
    text-decoration: none;
    font-size: 1.2em;
  }
}

.heroImageContainer {
  div:nth-child(2) {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 20px;

    p {
      color: white;
      font-size: 1.3em;
    }
  }

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -1px;
    left: 0;
    background-image: url("/images/ws-border-13.svg");
    background-position: center center;
    background-repeat: repeat-x;
    background-size: 1366px 13px;
    width: 100%;
    height: 13px;
  }
}

.orangeBackground {
  background-color: rgb(212, 237, 244) !important;
}

.bodyHeading {
  color: $color-salmon;
}

.galleryContainer#Menu {
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    background-image: url("/images/ws-border-bottom.svg");
    background-position: center center;
    background-repeat: repeat-x;
    background-size: 1366px 12px;
    height: 12px;
    width: 102%;
  }
}

#footer {
  background-color: $color-lightBlue  !important;
  /* Set a specific height */
  // min-height: 341px;
  position: fixed;
  width: calc(100vw);
  bottom: 0;
  left: 0;
  z-index: -1;

  &::after {
    content: '';
    height: 1150px;
    width: 100%;
    margin-top: 0px;
    display: none;
    background-image: url("/images/ws_textured_bottom_leftrightedge.png");
    background-position: top center;
    background-repeat: repeat-x;
    background-size: 1358px 13px;
    z-index: 1;
    position: relative;
    pointer-events: none;
  }

  .footerLinks span {

    a:visited,
    a:active,
    a:hover,
    a:link {
      color: $color-peopleOrange;
      text-decoration: none;
    }
  }
}

.menuTitle {
  div {
    color: $color-salmon  !important;
  }
}

.formHolder {
  label {
    color: $color-salmon  !important;
  }

  fieldset {
    border: 2px solid $color-marigold  !important;
  }

  .submitButton {
    color: $color-salmon;
    background-color: $color-lightBlue;
  }
}

.peopleOrange {
  color: $color-peopleOrange;
}


.mobileIcon {
  cursor: pointer;
}

.mobileIcon {
  svg {
    transform: translate(0px, 2px);

    &.smaller {
      transform: translate(0px, 5px);
    }
  }
}

.mobileMenu {
  align-items: start;
}

@media screen and (min-width: 800px) {
  header {
    height: 7.5em;
  }

  header.smaller {
    height: 6em;
  }

  .logoHolder {
    svg {
      width: 80%;

      &#boy {
        transform: translate(-50px, -20px);
      }

      &#logo {
        transform: translate(-135px, 0px);
      }

      &#girl {
        transform: translate(-200px, 7px);
      }

      &.smaller {
        width: 70%;

        &#boy {
          transform: translate(-50px, -20px);
        }

        &#logo {
          transform: translate(-155px, 0px);
        }

        &#girl {
          transform: translate(-240px, 3px);
        }
      }
    }
  }

  .mobileIcon {
    svg {
      transform: translate(0px, 8px);

      &.smaller {
        transform: translate(0px, 12px);
      }
    }
  }

  .galleryContainer#Menu {
    &::before {
      width: 101%;
    }
  }
}

@media screen and (min-width: 1000px) {
  header {
    height: 8em;
  }

  header.smaller {
    height: 6.5em;
  }

  .menuText {

    a,
    a:visited,
    a:active,
    a:hover,
    a:link {
      font-size: 1.25em;
    }
  }

  .logoHolder {
    svg {
      width: 110%;

      &#boy {
        transform: translate(-25px, -24px);
      }

      &#logo {
        transform: translate(-60px, -8px);
      }

      &#girl {
        transform: translate(-75px, 6px);
      }

      &.smaller {
        width: 95%;

        &#boy {
          transform: translate(-25px, -20px);
        }

        &#logo {
          transform: translate(-75px, -11px);
        }

        &#girl {
          transform: translate(-105px, 6px);
        }
      }
    }
  }
}

@media screen and (min-width: 1240px) {
  header {
    height: 9em;
  }

  header.smaller {
    height: 7em;
  }

  .heroImageContainer {
    width: 98vw !important;
    height: 92vh !important;

    div:nth-child(2) {
      background-color: rgba(0, 0, 0, 0.3);
      padding: 20px;

      p {
        color: white;
        font-size: 1.3em;
      }
    }
  }

  .logoHolder {
    svg {
      width: 100%;

      &#boy {
        transform: translate(-25px, -25px);
      }

      &#logo {
        transform: translate(-70px, -11px);
      }

      &#girl {
        transform: translate(-90px, 9px);
      }

      &.smaller {
        width: 80%;

        &#boy {
          transform: translate(-25px, -20px);
        }

        &#logo {
          transform: translate(-100px, -11px);
        }

        &#girl {
          transform: translate(-160px, 6px);
        }
      }
    }
  }

  .galleryContainer#Menu {
    &::before {
      width: 100.5%;
    }
  }

  #footer {
    width: calc(99vw)
  }
}